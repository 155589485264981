<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unused-vars -->
<template>
  <!-- BOOKING - START -->

  <div
    class="template-booking"
    ref="templatebooking"
    v-if="display"
    :style="{ visibility: showBooking }"
  >
    <div class="booking-resource-logo" ref="resourcelogo">
      <div
        class="resource"
        ref="resource"
        :style="[
          this.black == true
            ? { backgroundColor: '#262629' }
            : { backgroundColor: '#FFFFFF' },
        ]"
      >
        <icon
          icon="#cx-app1-arrow-left"
          class="booking-back-to-main-page"
          v-on:click="showMainDisplay"
          v-on:touchend="showMainDisplay"
        ></icon>
        <p
          class="booking-name"
          :style="[
            this.black == true ? { color: '#FFFFFF' } : { color: '#373F4E' },
          ]"
        >
          {{ display.resource.name }}
        </p>
        <p
          class="availability"
          :style="[
            this.black == true ? { color: '#C2C2C2' } : { color: '#737373' },
          ]"
        >
          {{ display.labels.immediate_booking }}
        </p>
      </div>
      <div
        v-if="logoUp || logoRec"
        class="logo-up-rec"
        ref="bookinglogo"
        id="logo"
        :style="[
          this.black == true
            ? { backgroundColor: '#262629' }
            : { backgroundColor: '#FFFFFF' },
        ]"
      >
        <div class="clebex-logo" v-if="!display.logo">
          <img
            :src="[
              this.black == true
                ? require('@/assets/logowhite@2x.png')
                : '/img/logo@2x.3b392d37.png',
            ]"
          />
        </div>
        <div class="company-logo" v-if="display.logo">
          <img :src="display.logo" />
        </div>
        <div class="powered-by-clebex" v-if="display.logo && black == false">
          <powered-by-clebex />
        </div>
        <div class="powered-by-clebex" v-if="display.logo && black == true">
          <powered-by-clebex-white />
        </div>
      </div>
    </div>
    <div
      class="booking-info"
      ref="bookinginfo"
      :style="[
        this.black == true
          ? { backgroundColor: '#262629' }
          : { backgroundColor: '#FFFFFF' },
      ]"
    >
      <div
        class="booking-info-clock"
        :style="[
          this.black == true ? { fill: '#C2C2C2' } : { fill: '#9d9c9c' },
        ]"
      >
        <icon icon="#cx-app1-time"></icon>
      </div>
      <div class="booking-info-times">
        <div class="booking-info-time">
          <p
            class="booking-info-text"
            v-if="bookingFrom"
            style="width: 20%;"
            :style="[
              this.black == true ? { color: '#FFFFFF' } : { color: '##737373' },
            ]"
          >
            {{ display.labels.from }}
          </p>

          <p
            class="booking-info-text"
            v-if="bookingFrom"
            :style="[
              this.black == true ? { color: '#FFFFFF' } : { color: '##737373' },
            ]"
          >
            {{ bookingFrom }}
          </p>
        </div>
        <div class="booking-info-time">
          <p
            class="booking-info-text"
            v-if="bookingFrom"
            style="width: 20%;"
            :style="[
              this.black == true ? { color: '#FFFFFF' } : { color: '##737373' },
            ]"
          >
            {{ display.labels.to }}
          </p>

          <p
            class="booking-info-text"
            v-if="bookingTo"
            :style="[
              this.black == true ? { color: '#FFFFFF' } : { color: '##737373' },
            ]"
          >
            {{ bookingTo }}
          </p>
        </div>
      </div>
    </div>
    <div class="booking-options">
      <div class="booking-options-display">
        {{ display.labels.meeting_duration }} <br /><br />
        <p style="margin-top: -10px;">{{ bookingTimeDifference }}</p>
      </div>
      <div
        class="booking-options-control"
        @click="bookingDecreaseStep()"
        style="padding-bottom: 2vh;"
      >
        -
      </div>
      <div class="booking-options-control" @click="bookingIncreaseStep()">
        +
      </div>
      <div
        class="booking-options-control"
        style="background-color: #19aa00;"
        :style="[
          this.bookingInProgress == true ? { opacity: 0.7 } : { opacity: 1 },
        ]"
        @click="displayActionBooking()"
      >
        <svg-icon class="booking-icon" icon="anonymous-checkin"></svg-icon>
      </div>
    </div>
  </div>

  <!-- BOOKING - END -->

  <!-- CALENDAR - START -->

  <div
    class="template-calendar"
    ref="templatecalendar"
    :style="{ visibility: showCalendar }"
  >
    <icon
      icon="#cx-app1-arrow-left"
      class="back-to-main-page"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></icon>

    <div class="display-calendar-header">
      <p v-if="display">{{ display.today }}</p>
      <p v-if="display">{{ display.tomorrow }}</p>
    </div>
    <div class="display-calendar-content" ref="calendarcontent">
      <div
        v-if="display"
        :class="[
          'calendar-qr-code',
          status.class == 'checkin' ? 'calendar-qr-code-checkin' : '',
          status.class == 'busy' || status.class == 'declined'
            ? 'calendar-qr-code-busy'
            : '',
        ]"
      >
        <img :src="display.qrcode" />
      </div>
      <!-- TABLE: TODAY MORNING - START -->
      <div class="display-calendar-table" v-if="display && display.calendar">
        <div
          :from="item.from"
          :to="item.to"
          class="display-calendar-item"
          :class="[
            item.meeting
              ? 'display-calendar-table-meeting'
              : 'display-calendar-table-no-meeting',
            item.start ? 'display-calendar-item-start' : '',
            item.end ? 'display-calendar-item-end' : '',
          ]"
          v-for="item in display.calendar.today_morning"
          :key="item.from"
        >
          <div class="display-calendar-item-info">
            <p class="display-calendar-times" v-if="item.times">
              {{ item.times }}
            </p>
            <p class="display-calendar-subject" v-if="item.subject">
              {{ item.subject }}
            </p>
            <p class="display-calendar-host" v-if="item.host">
              {{ item.host }}
            </p>
          </div>
          <div
            v-if="display.meeting && item.meeting == display.meeting.id"
            :class="[
              status.class == 'checkin'
                ? 'display-calendar-item-status-checkin'
                : 'display-calendar-item-status-busy',
            ]"
          ></div>
        </div>
      </div>

      <!-- TABLE: TODAY MORNING - END -->
      <!-- TABLE: TODAY AFTERNOON - START -->

      <div class="display-calendar-table" v-if="display && display.calendar">
        <div
          :from="item.from"
          :to="item.to"
          class="display-calendar-item"
          :class="[
            item.meeting
              ? 'display-calendar-table-meeting'
              : 'display-calendar-table-no-meeting',
            item.start ? 'display-calendar-item-start' : '',
            item.end ? 'display-calendar-item-end' : '',
          ]"
          v-for="item in display.calendar.today_afternoon"
          :key="item.from"
        >
          <div class="display-calendar-item-info">
            <p class="display-calendar-times" v-if="item.times">
              {{ item.times }}
            </p>
            <p class="display-calendar-subject" v-if="item.subject">
              {{ item.subject }}
            </p>
            <p class="display-calendar-host" v-if="item.host">
              {{ item.host }}
            </p>
          </div>
          <div
            v-if="display.meeting && item.meeting == display.meeting.id"
            :class="[
              status.class == 'checkin'
                ? 'display-calendar-item-status-checkin'
                : 'display-calendar-item-status-busy',
            ]"
          ></div>
        </div>
      </div>

      <!-- TABLE: TODAY AFTERNOON - END -->
      <!-- TABLE: TOMORROW MORNING - START -->

      <div class="display-calendar-table" v-if="display && display.calendar">
        <div
          :from="item.from"
          :to="item.to"
          class="display-calendar-item"
          :class="[
            item.meeting
              ? 'display-calendar-table-meeting'
              : 'display-calendar-table-no-meeting',
            item.start ? 'display-calendar-item-start' : '',
            item.end ? 'display-calendar-item-end' : '',
          ]"
          v-for="item in display.calendar.tomorrow_morning"
          :key="item.from"
        >
          <div class="display-calendar-item-info">
            <p class="display-calendar-times" v-if="item.times">
              {{ item.times }}
            </p>
            <p class="display-calendar-subject" v-if="item.subject">
              {{ item.subject }}
            </p>
            <p class="display-calendar-host" v-if="item.host">
              {{ item.host }}
            </p>
          </div>
          <div
            v-if="display.meeting && item.meeting == display.meeting.id"
            :class="[
              status.class == 'checkin'
                ? 'display-calendar-item-status-checkin'
                : 'display-calendar-item-status-busy',
            ]"
          ></div>
        </div>
      </div>

      <!-- TABLE: TOMORROW MORNING - END -->
      <!-- TABLE: TOMORROW AFTERNOON - START -->

      <div class="display-calendar-table" v-if="display && display.calendar">
        <div
          :from="item.from"
          :to="item.to"
          class="display-calendar-item"
          :class="[
            item.meeting
              ? 'display-calendar-table-meeting'
              : 'display-calendar-table-no-meeting',
            item.start ? 'display-calendar-item-start' : '',
            item.end ? 'display-calendar-item-end' : '',
          ]"
          v-for="item in display.calendar.tomorrow_afternoon"
          :key="item.from"
        >
          <div class="display-calendar-item-info">
            <p class="display-calendar-times" v-if="item.times">
              {{ item.times }}
            </p>
            <p class="display-calendar-subject" v-if="item.subject">
              {{ item.subject }}
            </p>
            <p class="display-calendar-host" v-if="item.host">
              {{ item.host }}
            </p>
          </div>
          <div
            v-if="display.meeting && item.meeting == display.meeting.id"
            :class="[
              status.class == 'checkin'
                ? 'display-calendar-item-status-checkin'
                : 'display-calendar-item-status-busy',
            ]"
          ></div>
        </div>
      </div>

      <!-- TABLE: TOMORROW AFTERNOON - END -->
    </div>
  </div>

  <!-- CALENDAR - START -->

  <!-- VIDEO - START -->
  <div
    class="template-video"
    v-if="showVideo"
    v-on:click="showMainDisplay"
    v-on:touchend="showMainDisplay"
  >
    <div
      class="back-to-main-page-div"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></div>
    <iframe
      width="100%"
      height="100%"
      :src="videoUrl"
      title=""
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  </div>

  <!-- VIDEO - END -->

  <!-- MAP - START -->
  <div class="template-map" :style="{ visibility: showMap }">
    <icon
      icon="#cx-app1-arrow-left"
      class="back-to-main-page"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></icon>
    <iframe
      ref="mapIframe"
      style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
      :src="mapUrl"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
    <div class="map-date-time" v-if="mapDate">
      <span class="map-time">{{ mapFrom }} - {{ mapTo }}</span>
      <span class="map-date">{{ mapDate }}</span>
    </div>
  </div>

  <!-- MAP - END -->

  <!-- VIDEOCONFERENCE - START -->

  <div class="template-videoconference" v-if="showVideoConference">
    <icon
      icon="#cx-app1-arrow-left"
      class="back-to-main-page"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></icon>
    <iframe
      style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
      :src="videoConferenceUrl"
      frameborder="0"
      width="100%"
      height="100%"
      allow="camera; microphone"
    ></iframe>
  </div>

  <!-- VIDEOCONFERENCE - END -->

  <div
    class="display-wrapper"
    v-if="showDisplay"
    :style="[
      this.black == true
        ? { backgroundColor: '#000000' }
        : { backgroundColor: '#EFEFF4' },
    ]"
  >
    <!-- ERROR SCREEN - START -->

    <div class="template-error" v-if="display.error">
      <img src="/img/logo@2x.3b392d37.png" />
      <div class="resource">{{ display.resource }}</div>
      <div class="message">{{ display.message }}</div>
    </div>

    <!-- ERROR SCREEN - END -->

    <!-- TEMPLATE 1 - START (also refers to template 3 and 4) -->

    <div
      class="template-1"
      v-if="!display.error && [1, 3, 4].includes(display.template)"
      :style="[
        this.black == true
          ? { backgroundColor: '#000000' }
          : { backgroundColor: '#EFEFF4' },
      ]"
    >
      <div class="left" ref="leftpart">
        <div class="resource-logo" ref="resourcelogo">
          <div
            class="resource"
            ref="resource"
            :style="[
              this.black == true
                ? { backgroundColor: '#262629' }
                : { backgroundColor: '#FFFFFF' },
            ]"
          >
            <p
              class="name"
              :style="[
                this.black == true
                  ? { color: '#FFFFFF' }
                  : { color: '#373F4E' },
              ]"
            >
              {{ display.resource.name }}
            </p>
            <p
              class="availability"
              :class="{ [status.text]: !showQr }"
              :style="{
                color: this.black ? '#C2C2C2' : '',
              }"
            >
              {{ display.status.message }}
            </p>
            <span class="now">{{ now }}</span>
          </div>
          <div
            v-if="logoUp || logoRec"
            class="logo-up-rec"
            ref="logo"
            id="logo"
            :style="[
              this.black == true
                ? { backgroundColor: '#262629' }
                : { backgroundColor: '#FFFFFF' },
            ]"
          >
            <div class="clebex-logo" v-if="!display.logo">
              <img
                :src="[
                  this.black == true
                    ? require('@/assets/logowhite@2x.png')
                    : '/img/logo@2x.3b392d37.png',
                ]"
              />
            </div>
            <div class="company-logo" v-if="display.logo">
              <img :src="display.logo" />
            </div>
            <div
              class="powered-by-clebex"
              v-if="display.logo && black == false"
            >
              <powered-by-clebex />
            </div>
            <div class="powered-by-clebex" v-if="display.logo && black == true">
              <powered-by-clebex-white />
            </div>
          </div>
        </div>
        <div
          class="meeting"
          ref="meeting"
          :style="[
            display.meeting || display.available_until
              ? { visibility: 'visible' }
              : { visibility: 'hidden' },
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' },
          ]"
        >
          <div class="available-until" v-if="!display.meeting">
            <p
              class="available-until-label"
              :style="[
                this.black == true
                  ? { color: '#FFFFFF' }
                  : { color: '#373F4E' },
              ]"
            >
              {{ display.labels.available_until }}
            </p>
            <p
              class="available-until-date-time"
              :style="[
                this.black == true
                  ? { color: '#C2C2C2' }
                  : { color: '#737373' },
              ]"
            >
              {{ display.available_until }}
            </p>
          </div>
          <div class="info" v-if="display.meeting">
            <p
              class="subject"
              v-if="display.meeting.subject"
              :style="[
                this.black == true
                  ? { color: '#FFFFFF' }
                  : { color: '#373F4E' },
              ]"
            >
              {{ display.meeting.subject }}
            </p>
            <p
              class="host"
              :style="[
                this.black == true
                  ? { color: '#C2C2C2' }
                  : { color: '#737373' },
              ]"
            >
              {{ display.meeting.host }}
            </p>
          </div>
          <div
            class="times"
            ref="times"
            v-if="display.meeting && display.meeting.from && display.meeting.to"
            :style="[
              this.black == true
                ? { backgroundColor: '#4B4B4E' }
                : { backgroundColor: '#EFEFF4' },
            ]"
          >
            <div
              class="clock"
              :style="[
                this.black == true ? { fill: '#C2C2C2' } : { fill: '#737373' },
              ]"
            >
              <icon icon="#cx-app1-time"></icon>
            </div>
            <p
              class="time"
              :style="[
                this.black == true
                  ? { color: '#A3A3A3' }
                  : { color: '#A3A3A3' },
              ]"
            >
              {{ display.meeting.from }}
            </p>
            <p
              class="time"
              :style="[
                this.black == true
                  ? { color: '#A3A3A3' }
                  : { color: '#A3A3A3' },
              ]"
            >
              {{ display.meeting.to }}
            </p>
          </div>
        </div>
        <div
          class="options"
          :style="[display.template == 1 ? 'align-items: center' : '']"
        >
          <div
            v-if="!logoUp && !logoRec"
            class="logo"
            id="logo"
            ref="logo"
            :style="[
              this.black == true
                ? { backgroundColor: '#262629' }
                : { backgroundColor: '#FFFFFF' },
            ]"
          >
            <div class="clebex-logo" v-if="!display.logo">
              <img
                :src="[
                  this.black == true
                    ? require('@/assets/logowhite@2x.png')
                    : '/img/logo@2x.3b392d37.png',
                ]"
              />
            </div>
            <div class="company-logo" v-if="display.logo">
              <img :src="display.logo" />
            </div>
            <div
              class="powered-by-clebex"
              v-if="display.logo && black == false"
            >
              <powered-by-clebex />
            </div>
            <div class="powered-by-clebex" v-if="display.logo && black == true">
              <powered-by-clebex-white />
            </div>
          </div>
          <div
            class="next-meetings-wrapper"
            ref="nextmeetingswrapper"
            v-if="display.template == 3 || display.template == 4"
          >
            <div
              class="next-meetings-container"
              :style="[
                this.black == true
                  ? { backgroundColor: '#262629' }
                  : { backgroundColor: '#FFFFFF' },
                this.noQr == true
                  ? { alignItems: 'center' }
                  : { alignItems: 'start' },
              ]"
            >
              <p class="next-meeting" v-if="!showQr && nextMeetings.length">
                {{ display.labels.next_meeting }}
              </p>
              <div
                style="display: flex; flex-direction: column;"
                v-if="nextMeetings.length > 0"
              >
                <div
                  class="next-meetings-row"
                  v-for="meeting in nextMeetings"
                  :key="meeting.id"
                  :style="[
                    this.black == true
                      ? { color: '#FFFFFF' }
                      : { color: '#373F4E' },
                  ]"
                >
                  {{ meeting.content }}
                </div>
              </div>
            </div>
            <div
              class="next-meetings-calendar"
              ref="nextmeetingscalendar"
              v-if="calendar"
            >
              <icon
                icon="#cx-men1-booking"
                v-on:click="showCalendarDisplay"
                v-on:touchend="showCalendarDisplay"
              ></icon>
            </div>
          </div>
          <div
            class="option-container"
            v-if="display.map && display.template == 1"
          >
            <div
              class="map"
              ref="map"
              v-on:click="showMapDisplay"
              v-on:touchend="showMapDisplay"
            >
              <icon style="width: 10vh;" icon="#cx-app1-5d-maps"></icon>
            </div>
            <span class="option-text">{{ display.labels.map }}</span>
          </div>
          <div class="option-container" v-if="display.template == 1">
            <div
              class="calendar"
              ref="calendar"
              v-on:click="showCalendarDisplay"
              v-on:touchend="showCalendarDisplay"
            >
              <icon style="width: 10vh;" icon="#cx-men1-booking"></icon>
            </div>
            <span class="option-text">{{ display.labels.calendar }}</span>
          </div>
          <div
            class="option-container"
            v-if="display.video && display.template == 1"
          >
            <div
              class="video"
              ref="video"
              v-on:click="showVideoDisplay"
              v-on:touchend="showVideoDisplay"
            >
              <icon style="width: 10vh;" icon="#cx-men1-countries"></icon>
            </div>
            <span class="option-text">{{ display.labels.video }}</span>
          </div>
          <div
            class="option-container"
            v-if="display.videoconference && display.template == 1"
          >
            <div
              class="videoconference"
              ref="videoconference"
              v-on:click="showVideoConferenceDisplay"
              v-on:touchend="showVideoConferenceDisplay"
            >
              <icon style="width: 10vh;" icon="#cx-app1-parent"></icon>
            </div>
            <span class="option-text">{{
              display.labels.videoconference
            }}</span>
          </div>
        </div>
      </div>
      <div class="right" ref="rightpart">
        <div
          class="status"
          :class="status.class"
          ref="status"
          :style="{ cursor: status.mouse }"
          @click="displayAction()"
        >
          <div class="status-icon">
            <svg-icon :icon="status.icon"></svg-icon>
          </div>
          <div class="status-white" ref="statuswhite" v-if="showQr"></div>
          <div
            v-if="showQr"
            class="qr-code"
            ref="qrcode"
            :style="[
              this.black == true
                ? { backgroundColor: '#262629' }
                : { backgroundColor: '#FFFFFF' },
            ]"
          >
            <img
              :src="display.qrcode"
              :style="[
                this.black == true
                  ? { borderRadius: '9%' }
                  : { borderRadius: '9%' },
              ]"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- TEMPLATE 1 - END -->

    <!-- TEMPLATE 2 - START -->

    <div
      class="template-2"
      v-if="!display.error && display.template == 2"
      :style="[this.black ? backgroundColor : 'black']"
    >
      <div class="upper">
        <div class="resource-meeting">
          <div class="resource" ref="resource">
            <p class="name">{{ display.resource.name }}</p>
            <p class="availability">{{ display.status.message }}</p>
            <span class="now">{{ now }}</span>
          </div>
          <div
            class="meeting"
            :style="[
              display.meeting || display.available_until
                ? { visibility: 'visible' }
                : { visibility: 'hidden' },
            ]"
          >
            <div class="available-until" v-if="!display.meeting">
              <p
                class="available-until-label"
                :style="[
                  this.black == true
                    ? { color: '#FFFFFF' }
                    : { color: '#373F4E' },
                ]"
              >
                {{ display.labels.available_until }}
              </p>
              <p
                class="available-until-date-time"
                :style="[
                  this.black == true
                    ? { color: '#C2C2C2' }
                    : { color: '#737373' },
                ]"
              >
                {{ display.available_until }}
              </p>
            </div>
            <div class="info" v-if="display.meeting">
              <p class="subject">{{ display.meeting.subject }}</p>
              <p class="host">{{ display.meeting.host }}</p>
            </div>
            <div
              class="times"
              ref="times"
              v-if="
                display.meeting && display.meeting.from && display.meeting.to
              "
            >
              <div class="clock"><icon icon="#cx-app1-time"></icon></div>
              <p class="time">{{ display.meeting.from }}</p>
              <p class="time">{{ display.meeting.to }}</p>
            </div>
          </div>
        </div>

        <div class="options">
          <div class="option" v-if="display.map">
            <icon
              ref="map"
              v-on:click="showMapDisplay"
              v-on:touchend="showMainDisplay"
              icon="#cx-app1-5d-maps"
            ></icon>
            <span>{{ display.labels.map }}</span>
          </div>
          <div class="option">
            <icon
              icon="#cx-men1-booking"
              ref="calendar"
              v-on:click="showCalendarDisplay"
              v-on:touchend="showCalendarDisplay"
            ></icon>
            <span>{{ display.labels.calendar }}</span>
          </div>
          <div class="option" v-if="display.video">
            <icon
              icon="#cx-men1-countries"
              ref="video"
              v-on:click="showVideoDisplay"
              v-on:touchend="showMainDisplay"
            ></icon>
            <span>{{ display.labels.video }}</span>
          </div>
          <div
            class="option"
            v-if="display.videoconference && display.template == 2"
          >
            <icon
              icon="#cx-app1-parent"
              ref="videoconference"
              v-on:click="showVideoConferenceDisplay"
              v-on:touchend="showVideoConferenceDisplay"
            ></icon>
            <span>{{ display.labels.videoconference }}</span>
          </div>
        </div>
      </div>
      <div class="lower">
        <div
          class="status"
          :class="status.class"
          ref="status"
          :style="{ cursor: status.mouse }"
          @click="displayAction()"
        >
          <div class="logo">
            <div class="clebex-logo" v-if="!display.logo">
              <img src="/img/logo@2x.3b392d37.png" />
            </div>
            <div class="company-logo" v-if="display.logo">
              <img :src="display.logo" />
            </div>
            <div class="powered-by-clebex" v-if="display.logo">
              <powered-by-clebex />
            </div>
          </div>
          <div class="status-icon">
            <svg-icon class="status-icon-svg" :icon="status.icon"></svg-icon>
          </div>

          <div class="qr-code" ref="qrcode" v-if="showQr">
            <img :src="display.qrcode" />
          </div>
        </div>
      </div>
    </div>

    <!-- TEMPLATE 2 - END -->
    <!-- TEMPLATE 5 - START -->

    <div
      class="template-5"
      v-if="!display.error && [5].includes(display.template)"
    >
      <div class="upper">
        <div class="left" ref="leftpart">
          <div class="resource-logo" ref="resourcelogo">
            <div
              class="resource"
              ref="resource"
              :style="[
                this.black == true
                  ? { backgroundColor: '#262629' }
                  : { backgroundColor: '#FFFFFF' },
              ]"
            >
              <p
                class="name"
                :style="[
                  this.black == true
                    ? { color: '#FFFFFF' }
                    : { color: '#373F4E' },
                ]"
              >
                {{ display.resource.name }}
              </p>
              <p
                class="availability"
                :style="[
                  this.black == true
                    ? { color: '#C2C2C2' }
                    : { color: '#737373' },
                ]"
              >
                {{ display.status.message }}
              </p>
              <span class="now">{{ now }}</span>
            </div>
            <div
              v-if="logoUp || logoRec"
              class="logo-up-rec"
              ref="logo"
              id="logo"
              :style="[
                this.black == true
                  ? { backgroundColor: '#262629' }
                  : { backgroundColor: '#FFFFFF' },
              ]"
            >
              <div class="clebex-logo" v-if="!display.logo">
                <img
                  :src="[
                    this.black == true
                      ? require('@/assets/logowhite@2x.png')
                      : '/img/logo@2x.3b392d37.png',
                  ]"
                />
              </div>
              <div class="company-logo" v-if="display.logo">
                <img :src="display.logo" />
              </div>
              <div
                class="powered-by-clebex"
                v-if="display.logo && black == false"
              >
                <powered-by-clebex />
              </div>
              <div
                class="powered-by-clebex"
                v-if="display.logo && black == true"
              >
                <powered-by-clebex-white />
              </div>
            </div>
          </div>
          <div
            class="meeting"
            ref="meeting"
            :style="[
              display.meeting || display.available_until
                ? { visibility: 'visible' }
                : { visibility: 'hidden' },
              this.black == true
                ? { backgroundColor: '#262629' }
                : { backgroundColor: '#FFFFFF' },
            ]"
          >
            <div class="available-until" v-if="!display.meeting">
              <p
                class="available-until-label"
                :style="[
                  this.black == true
                    ? { color: '#FFFFFF' }
                    : { color: '#373F4E' },
                ]"
              >
                {{ display.labels.available_until }}
              </p>
              <p
                class="available-until-date-time"
                :style="[
                  this.black == true
                    ? { color: '#C2C2C2' }
                    : { color: '#737373' },
                ]"
              >
                {{ display.available_until }}
              </p>
            </div>
            <div class="info" v-if="display.meeting">
              <p
                class="subject"
                v-if="display.meeting.subject"
                :style="[
                  this.black == true
                    ? { color: '#FFFFFF' }
                    : { color: '#373F4E' },
                ]"
              >
                {{ display.meeting.subject }}
              </p>
              <p
                class="host"
                :style="[
                  this.black == true
                    ? { color: '#C2C2C2' }
                    : { color: '#737373' },
                ]"
              >
                {{ display.meeting.host }}
              </p>
            </div>
            <div
              class="times"
              ref="times"
              v-if="
                display.meeting && display.meeting.from && display.meeting.to
              "
              :style="[
                this.black == true
                  ? { backgroundColor: '#4B4B4E' }
                  : { backgroundColor: '#EFEFF4' },
              ]"
            >
              <div
                class="clock"
                :style="[
                  this.black == true
                    ? { fill: '#C2C2C2' }
                    : { fill: '#737373' },
                ]"
              >
                <icon icon="#cx-app1-time"></icon>
              </div>
              <p
                class="time"
                :style="[
                  this.black == true
                    ? { color: '#A3A3A3' }
                    : { color: '#A3A3A3' },
                ]"
              >
                {{ display.meeting.from }}
              </p>
              <p
                class="time"
                :style="[
                  this.black == true
                    ? { color: '#A3A3A3' }
                    : { color: '#A3A3A3' },
                ]"
              >
                {{ display.meeting.to }}
              </p>
            </div>
          </div>
        </div>
        <div class="right" ref="rightpart">
          <div
            class="status"
            :class="status.class"
            ref="status"
            :style="{ cursor: status.mouse }"
            @click="displayAction()"
          >
            <div class="status-icon">
              <svg-icon :icon="status.icon"></svg-icon>
            </div>
            <div class="status-white" ref="statuswhite"></div>
            <div
              v-if="showQr"
              class="qr-code"
              ref="qrcode"
              :style="[
                this.black == true
                  ? { backgroundColor: '#262629' }
                  : { backgroundColor: '#FFFFFF' },
              ]"
            >
              <img
                :src="display.qrcode"
                :style="[
                  this.black == true
                    ? { borderRadius: '5%' }
                    : { borderRadius: '0%' },
                ]"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="lower">
        <div
          v-if="!logoUp && !logoRec"
          class="logo"
          id="logo"
          ref="logo"
          :style="[
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' },
          ]"
        >
          <div class="clebex-logo" v-if="!display.logo">
            <img
              :src="[
                this.black == true
                  ? require('@/assets/logowhite@2x.png')
                  : '/img/logo@2x.3b392d37.png',
              ]"
            />
          </div>
          <div class="company-logo" v-if="display.logo">
            <img :src="display.logo" />
          </div>
          <div class="powered-by-clebex" v-if="display.logo && black == false">
            <powered-by-clebex />
          </div>
          <div class="powered-by-clebex" v-if="display.logo && black == true">
            <powered-by-clebex-white />
          </div>
        </div>
        <div class="next-meetings-wrapper" ref="nextmeetingswrapper">
          <div
            class="next-meetings-container"
            :style="[
              this.black == true
                ? { backgroundColor: '#262629' }
                : { backgroundColor: '#FFFFFF' },
            ]"
          >
            <div style="display: flex; flex-direction: column;">
              <div
                class="next-meetings-row"
                v-for="meeting in nextMeetings"
                :key="meeting.id"
                :style="[
                  this.black == true
                    ? { color: '#FFFFFF' }
                    : { color: '#373F4E' },
                ]"
              >
                {{ meeting.content }}
              </div>
            </div>
          </div>
          <div
            class="next-meetings-calendar"
            ref="nextmeetingscalendar"
            v-if="calendar"
          >
            <icon
              icon="#cx-men1-booking"
              v-on:click="showCalendarDisplay"
              v-on:touchend="showCalendarDisplay"
            ></icon>
          </div>
        </div>
      </div>
    </div>
    <!-- TEMPLATE 5 - END -->
  </div>
</template>

<script>
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import PoweredByClebex from "@/components/global/PoweredByClebex";
import PoweredByClebexWhite from "@/components/global/PoweredByClebexWhite";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";
import { displayCrestron } from "@/services/crestron";
import { displayQbic } from "@/services/qbic";
import { displayIadea } from "@/services/iadea";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "Display",
  components: {
    PoweredByClebex,
    PoweredByClebexWhite,
  },
  mixins: [mapMixin],
  data() {
    return {
      booking: false,
      bookingFrom: null,
      bookingFromDateTime: null,
      bookingTo: null,
      bookingToDateTime: null,
      bookingIncrement: 15,
      bookingTimeDifference: null,
      bookingInProgress: false,
      screensaver: null,
      screensaverTime: null,
      screensaverInterval: null,
      elementHeight: null,
      mapUrl: null,
      mapIframe: false,
      showMap: "hidden",
      showDisplay: null,
      showCalendar: "hidden",
      showBooking: "hidden",
      showVideo: null,
      showVideoConference: false,
      observer: null,
      display: null,
      template: 1,
      resize: false,
      connectorChangeChannel: null,
      companyProfileChannel: null,
      resourceChangeChannel: null,
      declarationChangeChannel: null,
      levelChangeChannel: null,
      status: {
        icon: "checkmark",
        class: "accepted",
        mouse: "auto",
        text: "text-accepted",
      },
      mapDate: null,
      mapFrom: null,
      mapTo: null,
      black: false,
      nextMeetings: [],
      nextMeetingsInitial: false,
      anonymousCheckin: false,
      calendar: false,
      logoUp: false,
      logoRec: false,
      now: null,
      checkinInProgress: false,
      checkoutInProgress: false,
      showQr: true,
    };
  },
  async mounted() {
    await this.initialize();
    await this.subscribe();
    this.updateTime();
  },
  computed: {
    code() {
      const { code } = this.$route.params;
      return code;
    },
  },
  methods: {
    convertTo24HourFormat(timeString) {
      var [time, period] = timeString.split(" ");
      var [hours, minutes] = time.split(":");

      hours = parseInt(hours);
      minutes = parseInt(minutes);

      if (period === "PM" && hours < 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }

      return `${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    },
    bookingIncreaseStep() {
      var date1, date2;
      date1 = this.bookingTo.includes("M")
        ? this.convertTo24HourFormat(this.bookingTo)
        : this.bookingTo;
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var day = today.getDate();
      date1 = new Date(
        year +
          "-" +
          (month < 10 ? "0" + month : month) +
          "-" +
          (day < 10 ? "0" + day : day) +
          "T" +
          date1 +
          ":00"
      );
      date1 = new Date(date1.getTime() + 15 * 60000);
      date2 = new Date(this.display.available_until_datetime);

      if (date1 < date2) {
        this.bookingIncrement += 15;
        this.updateTime();
      }
    },
    bookingDecreaseStep() {
      if (this.bookingIncrement > 15) {
        this.bookingIncrement -= 15;
        this.updateTime();
      }
    },
    updateTime() {
      // Get the current time
      let currentTime = new Date();

      this.now = this.formatTime(currentTime);
      this.bookingFromDateTime = currentTime;
      this.bookingToDateTime = new Date(
        currentTime.getTime() + this.bookingIncrement * 60000
      );
      this.bookingFrom = this.formatTime(this.bookingFromDateTime);
      this.bookingTo = this.formatTime(this.bookingToDateTime);

      this.calculateTimeDifference();

      // Calculate the time remaining until the next minute starts
      let millisecondsUntilNextMinute =
        60000 -
        (currentTime.getSeconds() * 1000 + currentTime.getMilliseconds());

      // Schedule the next update after the remaining time
      setTimeout(this.updateTime, millisecondsUntilNextMinute);
    },
    formatTime(time) {
      let response = "";
      let hours = time.getHours();
      let minutes = time.getMinutes();

      if (!this.display.now.includes("M")) {
        response = this.padZero(hours) + ":" + this.padZero(minutes);
      } else {
        let ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // If hours is 0, make it 12
        minutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero if minutes is less than 10
        response = hours + ":" + minutes + " " + ampm;
      }
      return response;
    },
    padZero(num) {
      return num < 10 ? "0" + num : num;
    },
    calculateTimeDifference() {
      // Convert the date-time values to milliseconds
      let startTimeMilliseconds = this.bookingFromDateTime.getTime();
      let endTimeMilliseconds = this.bookingToDateTime.getTime();

      // Calculate the difference in milliseconds
      let differenceMilliseconds = Math.abs(
        endTimeMilliseconds - startTimeMilliseconds
      );

      // Calculate hours and minutes
      let hours = Math.floor(differenceMilliseconds / (1000 * 60 * 60));
      let minutes = Math.floor(
        (differenceMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );

      // Format the difference as HH:mm
      let formattedDifference =
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes;

      this.bookingTimeDifference = formattedDifference;
    },
    calculateNumberOfLinesInNextMeetings() {
      if (!this.showQr) {
        if (this.display.next_meetings.length) {
          this.nextMeetings = [this.display.next_meetings[0]];
        }
      } else {
        var rows = document.getElementsByClassName("next-meetings-row");

        for (var i = 0; i < rows.length; i++) {
          let rowLines = 0;
          var row = rows[i];
          var style = window.getComputedStyle(row);
          var height = row.clientHeight;
          var fontSize = parseInt(style.fontSize);
          var numberOfLines = Math.floor(height / fontSize);
          rowLines = Math.max(rowLines, numberOfLines);
          this.display.next_meetings = this.display.next_meetings.map(
            (nm, index) => (index === i ? { ...nm, lines: rowLines } : nm)
          );
        }
        let totalMeetingLines = 0;
        this.nextMeetings = [];
        this.display.next_meetings.forEach((meeting) => {
          if (meeting.lines + totalMeetingLines <= 4) {
            totalMeetingLines += meeting.lines;
            this.nextMeetings.push(meeting);
          }
        });
      }
    },
    async initialize() {
      this.display = await this.getDisplayInfo();
      this.display = this.display.data;
      this.showDisplay = true;
      this.setTemplate();
      this.setStatus();

      if (!this.display.error) {
        this.now = this.display.now;
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.setDisplaySize();
          });
        });

        if (!this.resize) {
          window.addEventListener("resize", () => {
            this.setDisplaySize();
          });
          this.resize = true;
        }

        let resizeTimer;
        window.addEventListener("resize", () => {
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(() => {
            // Resize stopped, do something here
            this.calculateNumberOfLinesInNextMeetings();
          }, 250); // Adjust the timeout duration as needed
        });

        this.setMapUrl();
        this.displayHeartbeat();
        this.setScreensaver();
        this.setVideoconfrenceUrl();
        if (this.nextMeetingsInitial == false) {
          this.nextMeetings = this.display.next_meetings;
          this.nextMeetingsInitial = true;
        }
        this.nextMeetingsInitial = true;
      }
    },
    async subscribe() {
      if (this.display.error) return;
      this.connectorChangeChannel = subscribe(
        "connectors",
        [getSubdomain()],
        wsEvents.connectors.changed,
        this.connectorChangeCallback,
        true
      );

      this.companyProfileChannel = subscribe(
        "company-profile",
        [getSubdomain()],
        wsEvents.companyProfile.changed,
        this.companyProfileCallback,
        true
      );

      this.resourceChangeChannel = subscribe(
        "resource-functions",
        [getSubdomain(), this.display.resource.id],
        wsEvents.resourceChange.changed,
        this.resourceChangeCallback,
        true
      );
      this.declarationChangeChannel = subscribe(
        "declaration-changed",
        [getSubdomain(), this.display.resource.id],
        wsEvents.declarationChange.changed,
        this.declarationChangeCallback,
        true
      );
      this.levelChangeChannel = subscribe(
        "levels",
        [getSubdomain(), this.display.resource.level],
        wsEvents.levelChange.changed,
        this.levelChangeCallback,
        true
      );
    },
    setTemplate() {
      if (this.display && this.display.template) {
        this.black = this.display.template.toString().includes("BLACK")
          ? true
          : false;
        this.anonymousCheckin = this.display.template
          .toString()
          .includes("ANONYMOUS")
          ? true
          : false;

        this.showQr = this.display.template.toString().includes("NOQR")
          ? false
          : true;

        this.calendar = this.display.template.toString().includes("CALENDAR")
          ? true
          : false;

        this.booking = this.display.template.toString().includes("BOOKING")
          ? true
          : false;

        this.logoUp = this.display.template.toString().includes("LOGO_UP")
          ? true
          : false;

        this.logoRec = this.display.template.toString().includes("LOGO_REC")
          ? true
          : false;

        if (this.display.template.includes("NAME")) {
          this.display.template = 3;
        } else if (this.display.template.includes("HOST")) {
          this.display.template = 4;
        } else if (this.display.template.includes("BOTH")) {
          this.display.template = 5;
        } else if (this.display.template.includes("HORIZONTAL")) {
          this.display.template = 2;
        } else {
          this.display.template = 1;
        }
      }
    },
    displayAction() {
      // to create a booking
      if (this.booking && this.display.status.code == 1) {
        this.showBookingDisplay();
      }
      // to do check-in
      else if (this.anonymousCheckin && this.display.status.code == 2) {
        this.displayActionCheckIn();
      }
      // to do check-out
      else if (this.booking && this.display.status.code == 3) {
        this.displayActionCheckOut();
      }
    },
    displayActionBooking() {
      if (this.bookingInProgress == false) {
        this.bookingInProgress = true;

        httpServiceAuth
          .post(apiEndpoints.company.displayAnonymousBooking, {
            from: this.bookingFrom,
            to: this.bookingTo,
            resource_id: this.display.resource.id,
          })
          .finally(() => {
            this.bookingInProgress = false;
            this.showMainDisplay();
            this.initialize();
          });
      }
    },
    displayActionCheckIn() {
      if (this.checkinInProgress == false) {
        this.checkinInProgress = true;

        httpServiceAuth
          .get(
            `${apiEndpoints.company.displayAnonymousCheckin}/${this.display.meeting.id}`
          )
          .finally(() => {
            this.checkinInProgress = false;
            this.showMainDisplay();
            this.initialize();
          });
      }
    },
    displayActionCheckOut() {
      if (this.checkoutInProgress == false) {
        this.checkoutInProgress = true;

        httpServiceAuth
          .get(
            `${apiEndpoints.company.displayAnonymousCheckout}/${this.display.meeting.id}`
          )
          .finally(() => {
            this.checkoutInProgress = false;
            this.showMainDisplay();
            this.initialize();
          });
      }
    },
    setStatus() {
      this.status = {
        icon: null,
        class: null,
        mouse: "auto",
      };

      if (this.display && this.display.status) {
        if (this.display.status.code == 2) {
          this.status.class = "checkin";
          this.status.icon = this.anonymousCheckin
            ? "anonymous-checkin"
            : "check-in";
          this.status.mouse = this.anonymousCheckin ? "pointer" : "auto";
          this.status.text = "text-checkin";

          if (this.display.manufacturer) {
            if (this.display.manufacturer == "crestron") {
              try {
                displayCrestron.setOrange();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "qbic") {
              try {
                displayQbic.setOrange();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "iadea") {
              try {
                displayIadea.setColor("#FFA500");
              } catch (e) {
                // nothing
              }
            }
          }
        } else if (this.display.status.code == 3) {
          this.status.class = "declined";
          this.status.icon = this.booking ? "anonymous-checkin" : "x-cross";
          this.status.mouse = this.booking ? "pointer" : "auto";
          this.status.text = "text-declined";

          if (this.display.manufacturer) {
            if (this.display.manufacturer == "crestron") {
              try {
                displayCrestron.setRed();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "qbic") {
              try {
                displayQbic.setRed();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "iadea") {
              try {
                displayIadea.setColor("#FF0000");
              } catch (e) {
                // nothing
              }
            }
          }
        } else {
          this.status.class = "accepted";
          this.status.icon = this.booking ? "anonymous-checkin" : "checkmark";
          this.status.mouse = this.booking ? "pointer" : "auto";
          this.status.text = "text-accepted";

          if (this.display.manufacturer) {
            if (this.display.manufacturer == "crestron") {
              try {
                displayCrestron.setGreen();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "qbic") {
              try {
                displayQbic.setGreen();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "iadea") {
              try {
                displayIadea.setColor("#00ff00");
              } catch (e) {
                // nothing
              }
            }
          }
        }
      }
    },
    async getDisplayInfo() {
      let deviceToken = localStorage.getItem("device_token")
        ? localStorage.getItem("device_token")
        : this.generateRandomString(10);

      if (!localStorage.getItem("device_token"))
        localStorage.setItem("device_token", deviceToken);

      return httpServiceAuth.post(
        `${apiEndpoints.company.display}/${this.code}`,
        { device_token: localStorage.getItem("device_token") }
      );
    },
    setDisplaySize() {
      if (
        this.showMap == "hidden" &&
        this.showCalendar == "hidden" &&
        (this.showDisplay || this.showBooking)
      ) {
        if ([1, 3, 4, 5].includes(this.display.template)) {
          if (this.$refs.qrcode) {
            this.$refs.qrcode.style.height =
              this.$refs.resource.clientHeight + "px";
            this.$refs.qrcode.style.width =
              this.$refs.resource.clientHeight + "px";
          }
          if (this.$refs.status) {
            this.$refs.status.style.width =
              this.$refs.resource.clientHeight + "px";
          }

          if (this.$refs.logo) {
            this.$refs.logo.style.height =
              this.$refs.meeting.clientHeight + "px";
            this.$refs.logo.style.minWidth =
              this.$refs.meeting.clientHeight + "px";
          }

          if (this.$refs.bookinglogo) {
            this.$refs.bookinglogo.style.height =
              this.$refs.bookinginfo.clientHeight + "px";
            this.$refs.bookinglogo.style.minWidth =
              this.$refs.bookinginfo.clientHeight + "px";
          }

          // if (this.display.meeting)
          //   this.$refs.times.style.width = this.$refs.times.clientHeight + "px";
          if (this.display.template == 1) {
            this.$refs.calendar.style.height =
              this.$refs.logo.clientHeight * 0.6 + "px";
            this.$refs.calendar.style.width =
              this.$refs.calendar.clientHeight + "px";

            if (this.display.map) {
              this.$refs.map.style.height =
                this.$refs.logo.clientHeight * 0.6 + "px";
              this.$refs.map.style.width = this.$refs.map.clientHeight + "px";
            }

            if (this.display.video) {
              this.$refs.video.style.height =
                this.$refs.logo.clientHeight * 0.6 + "px";
              this.$refs.video.style.width =
                this.$refs.video.clientHeight + "px";
            }

            if (this.display.videoconference) {
              this.$refs.videoconference.style.height =
                this.$refs.logo.clientHeight * 0.6 + "px";
              this.$refs.videoconference.style.width =
                this.$refs.videoconference.clientHeight + "px";
            }
          }
          if ([3, 4, 5].includes(this.display.template)) {
            if (this.$refs.nextmeetingswrapper) {
              this.$refs.nextmeetingswrapper.style.height =
                this.$refs.meeting.clientHeight + "px";
            }
          }
          if (this.$refs.nextmeetingscalendar) {
            this.$refs.nextmeetingscalendar.style.width =
              this.$refs.nextmeetingscalendar.clientHeight + "px";
          }
          if (this.$refs.logo) {
            this.$refs.logo.style.height =
              this.$refs.resourcelogo.clientHeight + "px";
            if (this.logoRec == true) {
              this.$refs.logo.style.width =
                this.$refs.logo.clientHeight * 1.5 + "px";
            } else {
              this.$refs.logo.style.width = this.$refs.logo.clientHeight + "px";
            }
          }
          if (this.$refs.bookinglogo) {
            if (this.logoRec == true) {
              this.$refs.bookinglogo.style.width =
                this.$refs.bookinglogo.clientHeight * 1.5 + "px";
            } else {
              this.$refs.bookinglogo.style.width =
                this.$refs.bookinglogo.clientHeight + "px";
            }
          }
          if (this.$refs.times) {
            this.$refs.times.style.setProperty(
              "width",
              this.$refs.times.clientHeight + "px",
              "important"
            );
          }
        }

        if (this.black == true) {
          this.$refs.statuswhite.style.height =
            this.$refs.logo.clientHeight / 2 + "px";
        }
      }
    },
    async displayHeartbeat() {
      this.heartbeat();
      let self = this;
      setInterval(function() {
        self.heartbeat();
      }, 90000);
    },
    heartbeat() {
      httpServiceAuth.get(
        `${apiEndpoints.company.displayHeartbeat}/${this.display.resource.id}`
      );
    },
    async getMapDateTime() {
      return httpServiceAuth.get(
        `${apiEndpoints.company.displayMapDateTime}/${this.display.map}?lang=${this.display.language}`
      );
    },
    async setMapDateTime() {
      let mapDateTime = await this.getMapDateTime();
      this.mapDate = mapDateTime.data.date;
      this.mapFrom = mapDateTime.data.from;
      this.mapTo = mapDateTime.data.to;
    },
    async connectorChangeCallback() {
      this.nextMeetingsInitial = false;
      this.showMainDisplay();
      await this.initialize();
    },
    async companyProfileCallback() {
      this.nextMeetingsInitial = false;
      this.showMainDisplay();
      await this.initialize();
    },
    async resourceChangeCallback() {
      this.nextMeetingsInitial = false;
      this.showMainDisplay();
      await this.initialize();
    },
    async declarationChangeCallback() {
      this.nextMeetingsInitial = false;
      this.showMainDisplay();
      await this.initialize();
    },
    async levelChangeCallback() {
      this.nextMeetingsInitial = false;
      this.showMainDisplay();
      await this.initialize();
    },
    showMainDisplay() {
      this.setScreensaver();
      this.showDisplay = true;
      this.showVideo = false;
      this.showVideoConference = false;
      this.showMap = "hidden";
      this.showCalendar = "hidden";
      this.showBooking = "hidden";
      this.bookingIncrement = 15;
      this.updateTime();
      this.$nextTick(function() {
        this.setDisplaySize();
      });
      this.$nextTick(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            this.calculateNumberOfLinesInNextMeetings();
            if (this.$refs.nextmeetingswrapper) {
              this.$refs.nextmeetingswrapper.style.visibility = "visible";
            }
          }, 250);
          this.setDisplaySize();
        });
      });
    },
    showBookingDisplay() {
      this.setScreensaver();
      this.showCalendar = "hidden";
      this.showDisplay = false;
      this.showVideo = false;
      this.showVideoConference = false;
      this.showMap = "hidden";
      this.showBooking = "visible";
      this.bookingIncrement = 15;
    },
    showCalendarDisplay() {
      this.setScreensaver();
      this.showCalendar = "visible";
      this.showDisplay = false;
      this.showVideo = false;
      this.showVideoConference = false;
      this.showMap = "hidden";
    },
    showMapDisplay() {
      this.setScreensaver();
      this.showDisplay = false;
      this.showVideo = false;
      this.showVideoConference = false;
      this.showMap = "visible";
      this.showCalendar = "hidden";
      this.showBooking = "hidden";
      this.setMapDateTime();
      if (!this.mapIframe) this.setMapUrl();
      else {
        this.$refs.mapIframe.contentWindow.postMessage(
          {
            call: "displayMapChanged",
            value: "",
          },
          "*"
        );
      }
    },
    setMapUrl() {
      if (this.display.template == 1 || this.display.template == 2) {
        const subdomain = getSubdomain();
        this.mapUrl = `${process.env.VUE_APP_5DMAPS_URL}/display/${
          this.display.map
        }?api=${this.getMapApi()}&subdomain=${subdomain}&type=display&lang=${
          this.display.language
        }`;
        this.mapIframe = true;
      }
    },
    showVideoDisplay() {
      clearInterval(this.screensaverInterval);
      this.showDisplay = false;
      this.showVideoConference = false;
      this.showMap = "hidden";
      this.showCalendar = "hidden";
      this.showBooking = "hidden";
      this.showVideo = true;
      this.setVideoUrl();
    },
    setVideoUrl() {
      this.videoUrl = this.display.video.replace("watch?v=", "");
      if (this.videoUrl.includes("&")) {
        this.videoUrl = this.videoUrl.split("&")[0];
      }
      this.videoUrl =
        "https://www.youtube.com/embed/" +
        this.videoUrl +
        "?controls=0&amp;start=1&amp;&autoplay=1&amp;loop=1&amp;rel=0&amp;playlist=" +
        this.videoUrl;
    },
    showVideoConferenceDisplay() {
      clearInterval(this.screensaverInterval);
      this.showDisplay = false;
      this.showMap = "hidden";
      this.showCalendar = "hidden";
      this.showBooking = "hidden";
      this.showVideo = false;
      this.showVideoConference = true;
    },
    setVideoconfrenceUrl() {
      this.videoConferenceUrl = this.display.videoconference;
    },
    setScreensaver() {
      if (this.display.screensaver && this.display.video) {
        if (this.screensaverInterval) clearInterval(this.screensaverInterval);
        this.screensaver = this.display.screensaver;
        let self = this;
        this.screensaverInterval = setInterval(function() {
          self.screensaver--;
          if (self.screensaver < 0) {
            self.showVideoDisplay();
          }
        }, 1000);
      } else {
        clearInterval(this.screensaverInterval);
      }
    },
    generateRandomString(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    startVideoconference() {
      window.location.href = this.display.videoconference;
    },
  },
  watch: {
    nextMeetingsInitial() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            this.nextMeetings = this.display.next_meetings;
            this.calculateNumberOfLinesInNextMeetings();
            if (this.$refs.nextmeetingswrapper) {
              this.$refs.nextmeetingswrapper.style.visibility = "visible";
            }
          }, 250);
          this.setDisplaySize();
        });
      });
    },
  },
  beforeUnmount() {
    this.setScreensaver();
    if (this.connectorChangeChannel) {
      unsubscribe(this.connectorChangeChannel);
      this.connectorChangeChannel = null;
    }
    if (this.companyProfileChannel) {
      unsubscribe(this.companyProfileChannel);
      this.companyProfileChannel = null;
    }
    if (this.resourceChangeChannel) {
      unsubscribe(this.resourceChangeChannel);
      this.resourceChangeChannel = null;
    }
    if (this.declarationChangeChannel) {
      unsubscribe(this.declarationChangeChannel);
      this.declarationChangeChannel = null;
    }
    if (this.levelChangeChannel) {
      unsubscribe(this.levelChangeChannel);
      this.levelChangeChannel = null;
    }
  },
};
</script>
